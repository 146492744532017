import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllOwnersCellValue } from 'src/rbac/contributorHelper';
import { LabelledIdArray } from 'src/rbac/types';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import {
  Document_File_Type_Enum,
  GetPublicDocumentFilesQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

type DocumentFileTableFields = {
  Id: string;
  Title: string;
  Version: string;
  TypeLabel: string;
  Type: Document_File_Type_Enum;
  Link?: string | null;
  Status: string;
  FileId?: string | null;
  StatusLabelled?: string | null;
  Summary: string;
  ReviewDate: string;
  ReviewDue: string;
  allOwners: LabelledIdArray;
  ParentDocumentId: string;
  AttestationStatusLabel: string;
  AttestationStatus: string;
};

export type DocumentFile = GetPublicDocumentFilesQuery['document_file'][0];

const useGetFieldConfig = (
  editFile: (id: string) => void
): TableFields<DocumentFileTableFields> => {
  const { getByValue: getAttestationRecordStatusByValue } = useRating(
    'attestation_record_status'
  );
  const { getByValue } = useRating('document_file_status');
  const { t } = useTranslation('common');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.columns',
  });

  const getLink = (item: DocumentFileTableFields, label: string) => {
    return (
      <Link
        variant="secondary"
        href={'#'}
        onFollow={() => editFile(item.ParentDocumentId)}
      >
        {label}
      </Link>
    );
  };

  return {
    Title: {
      header: t('document_one'),
      cell: (item) => getLink(item, item.Title),
      sortingField: 'title',
      isRowHeader: true,
    },
    Version: {
      header: st('version'),
      cell: (item) => getLink(item, item.Version),
      sortingField: 'version',
      isRowHeader: true,
    },
    TypeLabel: {
      header: st('type'),
    },
    StatusLabelled: {
      header: st('status'),
      cell: (item) => {
        return <SimpleRatingBadge rating={getByValue(item.Status)} />;
      },
      sortingField: 'status',
    },
    AttestationStatusLabel: {
      header: st('attestationStatus'),
      cell: (item) =>
        item.AttestationStatus ? (
          <SimpleRatingBadge
            rating={getAttestationRecordStatusByValue(item.AttestationStatus)}
          />
        ) : (
          '-'
        ),
    },
    Summary: {
      header: st('summary'),
    },
    allOwners: useGetOwnersFieldConfig<DocumentFileTableFields>(),
    ReviewDate: dateColumn(st('reviewDate'), 'ReviewDate'),
    ReviewDue: dateColumn(st('reviewDue'), 'ReviewDue'),
  };
};

export const useGetCollectionTableProps = (
  records: DocumentFile[] | undefined,
  editFile: (id: string) => void
): TablePropsWithActions<DocumentFileTableFields> => {
  const { t: pt } = useTranslation('common', {
    keyPrefix: 'policy',
  });
  const documentTypes = pt('types', { returnObjects: true });
  const { getLabel: getDocumentFileStatusLabel } = useRating(
    'document_file_status'
  );
  const { getByValue: getAttestationRecordStatusByValue } = useRating(
    'attestation_record_status'
  );

  const labelledFields = useMemo<DocumentFileTableFields[] | undefined>(
    () =>
      records?.map((d) => {
        const attestation = d.attestations?.[0];
        return {
          Id: d.Id,
          Type: d.Type,
          TypeLabel:
            documentTypes[d.parent?.DocumentType as keyof typeof documentTypes],
          Title: d.parent?.Title || '-',
          Status: d.Status ?? '',
          StatusLabelled: getDocumentFileStatusLabel(d.Status ?? ''),
          ReviewDue: d.NextReviewDate || '-',
          ReviewDate: d.ReviewDate || '-',
          FileId: d.file?.Id,
          Link: d.Link,
          Version: d.Version,
          Summary: d.Summary || '-',
          ParentDocumentId: d.ParentDocumentId,
          allOwners: getAllOwnersCellValue(d.parent!),
          AttestationStatusLabel: attestation
            ? getAttestationRecordStatusByValue(attestation.AttestationStatus)
                ?.label ?? '-'
            : '-',
          AttestationStatus: attestation?.AttestationStatus,
        };
      }),
    [
      records,
      documentTypes,
      getDocumentFileStatusLabel,
      getAttestationRecordStatusByValue,
    ]
  );

  const fields = useGetFieldConfig(editFile);

  return useGetTableProps({
    data: labelledFields,
    entityLabel: pt('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'MyPolicies-Preferences',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'Version',
      'TypeLabel',
      'StatusLabelled',
      'AttestationStatusLabel',
      'Summary',
      'allOwners',
      'ReviewDate',
      'ReviewDue',
    ],
    fields,
  });
};
